var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.plan, _vm.cssPlan] },
    [
      _c(
        "b-card",
        [
          _c(
            "b-card-header",
            { staticStyle: { background: "none", border: "none" } },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.plans[_vm.positionPlan].name)),
              ]),
            ]
          ),
          _c(
            "b-card-body",
            [
              _c("div", { staticClass: "minuteValue" }, [
                _c("strong", [
                  _vm._v(
                    " R$ " +
                      _vm._s(_vm.adjustPricePlan(_vm.positionPlan)) +
                      " / min"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "price" }, [
                _c("p", [_vm._v(" R$ " + _vm._s(_vm.amountPlan) + " por mês")]),
              ]),
              _c("div", { staticClass: "for-months" }, [
                _c("p", [_vm._v(_vm._s(_vm.secondsQuantity) + "h / mês")]),
              ]),
              _c("p", { staticClass: "validade-minutagem" }, [
                _vm._v("Validade da minutagem: mensal"),
              ]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.buttonClicked,
                      expression: "buttonClicked",
                    },
                  ],
                  staticClass: "plan-button",
                  attrs: { size: "lg", variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.showWizard(_vm.plans[_vm.positionPlan].planId)
                    },
                  },
                },
                [_vm._v("Assinar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }